import LoaderLifestyleX from '@collinsonx/design-system/components/loaderLifestyleX';
import { Flex } from '@collinsonx/design-system/core';
import LoungeError from '@components/LoungeError';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import usePayload from 'hooks/payload';
import { PropsWithChildren } from 'react';

export default function OptimizelyWrapper({
  children,
}: Readonly<PropsWithChildren>) {
  const { fetchConsumerError, fetchConsumerLoading, session, tracking } =
    usePayload();

  return (
    <>
      <LoungeError error={fetchConsumerError} />

      {!session.loading &&
        tracking &&
        (fetchConsumerLoading ? (
          <Flex align="center" h="100%" justify="center">
            <LoaderLifestyleX />
          </Flex>
        ) : (
          <OptimizelyProvider
            optimizely={tracking}
            user={{ id: session.userId }}
          >
            {children}
          </OptimizelyProvider>
        ))}
    </>
  );
}
