import { GUEST_TYPES } from 'config/productDefaultConfig';
import { LangObj } from 'types/language';
import { MappedProductData } from 'types/mappedProductData';

export type SelectedGuestsCountType = {
  [GUEST_TYPES.adults]?: number;
  [GUEST_TYPES.children]?: number;
  [GUEST_TYPES.infants]?: number;
  [GUEST_TYPES.seniors]?: number;
};

export const getMaxGuestCount = (
  targetGuest: GUEST_TYPES,
  {
    guests,
    maxPartySize,
    selectedGuestsCount,
  }: {
    guests: MappedProductData['guests'];
    maxPartySize: MappedProductData['maxPartySize'];
    selectedGuestsCount: SelectedGuestsCountType;
  }
) => {
  const partyImpactGuestTypes = Object.entries(guests)
    .filter(([, guest]) => guest.isPartySizeImpacted)
    .map(([guestKey]) => guestKey as GUEST_TYPES);

  if (!partyImpactGuestTypes.find((guest) => guest === targetGuest))
    return guests[targetGuest]?.maxCountPerGuestType;

  const remainingGuestKeys = partyImpactGuestTypes.filter(
    (guest) => guest !== targetGuest
  );

  const selectedGuests = remainingGuestKeys.reduce(
    (sum, guestKey) => sum + (Number(selectedGuestsCount[guestKey]) || 0),
    0
  );

  return maxPartySize - selectedGuests;
};

export const getExcludedGuestFromPartyCountTranslation = (
  guests: MappedProductData['guests'],
  translations: LangObj
) => {
  const excludedGuestFromPartyCount = Object.entries(guests)
    .filter(([, guest]) => !guest.isPartySizeImpacted)
    .map(([guestKey]) => guestKey as GUEST_TYPES);

  return excludedGuestFromPartyCount
    .map((guest) => {
      const guestDetailsKey =
        guest as keyof typeof translations.booking.guestDetails;
      const guestDetailsTranslation = translations.booking.guestDetails[
        guestDetailsKey
      ] as {
        label: string;
      };

      return guestDetailsTranslation.label.toLowerCase();
    })
    .join(', ');
};

export const getPartyImpactingGuests = (guests: MappedProductData['guests']) =>
  Object.entries(guests)
    .filter(([, guest]) => guest.isPartySizeImpacted)
    .map(([guestKey]) => guestKey as GUEST_TYPES);

export const getTaxableGuests = (guests: MappedProductData['guests']) =>
  Object.entries(guests)
    .filter(([, guest]) => guest.isChargeable && guest.price > 0)
    .map(([guestKey]) => guestKey as GUEST_TYPES);

export const getTaxableGuestsCount = (
  selectedGuestsCount: SelectedGuestsCountType,
  guests: MappedProductData['guests']
) => {
  let totalTaxableGuestsSelected = 0;

  const taxableGuests = getTaxableGuests(guests);

  taxableGuests.forEach((guest) => {
    totalTaxableGuestsSelected += selectedGuestsCount[guest] || 0;
  });

  return totalTaxableGuestsSelected;
};

export const partyImpactingGuestsSelected = (
  selectedGuestsCount: SelectedGuestsCountType,
  guests: MappedProductData['guests']
) => {
  let totalGuestsSelected = 0;

  const partyImapctingGuests = getPartyImpactingGuests(guests);

  partyImapctingGuests.forEach((guest) => {
    totalGuestsSelected += selectedGuestsCount[guest] || 0;
  });

  return totalGuestsSelected;
};
