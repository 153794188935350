import { AccountProvider, Client } from '@collinsonx/constants/enums';
import { BridgePayload } from 'types/booking';

// Mobile events
export const MOBILE_ACTION_BACK = 1;
export const MOBILE_ACTION_DATA_URI = 'DATA_URI';

// Logging
export const PRODUCTION_DOMAIN = 'booking.cergea.com';

// User flow control
export enum BOOKING_MODE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

// API communication
export const OAG_API_VERSION = 'v2';
export const TRAVEL_TYPE = 'DEPARTURE';
export const CODE_IATA = 'IATA';
export const CODE_ICAO = 'ICAO';
export const DATE_FORMAT = 'YYYY-MM-DD';

// Meta data
export const SITE_NAME = 'Booking';

// Time formatting
export const TIME_FORMAT_DISPLAY = 'h:mma';
export const DATE_READABLE_FORMAT = 'ddd D MMMM YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const TIME_FORMAT = 'HH:mm';

// Custom configuration parameters
export type PROVIDER_PARAMS = {
  accountProvider?: AccountProvider;
  membershipType?: Client;
};

export const DEFAULT_PARTNER_KEY = 'partnerIdProd';

export const apiAccountProviderMap: Record<
  BridgePayload['accountProvider'],
  string
> = {
  LOUNGE_KEY: 'lK',
  PRIORITY_PASS: 'pP',
};

// Internalization
export const DEFAULT_LANGUAGE_CODE = 'en';
