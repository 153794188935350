import { DEFAULT_CONTEXT_PRODUCT_VALUE } from 'config/productDefaultConfig';
import React, { createContext, ReactNode, useMemo, useState } from 'react';
import { MappedProductData } from 'types/mappedProductData';

type ProductContextProps = {
  product: MappedProductData;
  setProduct: (product: MappedProductData) => void;
};

const defaultConextValue = {
  product: DEFAULT_CONTEXT_PRODUCT_VALUE,
  setProduct: () => {},
};

const setProductStorage = (product: MappedProductData) => {
  if (typeof window === 'undefined') return;

  sessionStorage.setItem('product', JSON.stringify(product));
};

const getProductStorage = (): false | MappedProductData => {
  if (typeof window === 'undefined') return false;

  return JSON.parse(sessionStorage.getItem('product') ?? 'false');
};

export const ProductContext =
  createContext<ProductContextProps>(defaultConextValue);

const ProductProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const productStorage = getProductStorage();
  const [product, setProductState] = useState<MappedProductData>(
    productStorage || defaultConextValue.product
  );

  const setProduct = (productValue: MappedProductData) => {
    setProductStorage(productValue);
    setProductState(productValue);
  };

  const productContextValue = useMemo(
    () => ({
      product,
      setProduct,
    }),
    [product]
  );

  return (
    <ProductContext.Provider value={productContextValue}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
