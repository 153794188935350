import { AccountProvider, Client } from '@collinsonx/constants/enums';
import { ErrorHandler, ErrorResponse } from '@collinsonx/utils/apolloBooking';
import LayoutError from '@components/LayoutError';
import { logDataError } from '@lib';
import { BookingError } from 'config/datadog';
import { createContext, useContext, useEffect, useState } from 'react';
import { IObservable } from 'utils/observer';

import usePayload from './payload';
import useLocale from './useLocale';

interface ErrorHookProps {
  apolloErrorObservable?: IObservable<ErrorResponse>;
  children: React.ReactNode;
}

type ErrorInfo = {
  button?: string;
  error: Error | null;
  isError: boolean;
  message?: string;
  mobileMessage?: string;
  title?: string;
};

type ServerErrorProps = {
  data?: unknown;
  location: string;
  name?: string;
};

type ErrorState = {
  error: ErrorInfo | undefined;
  setError: (errorInfo: ErrorInfo) => void;
};

type useErrorReturnType = {
  setServerError: (props: ServerErrorProps) => void;
} & ErrorState;

const ErrorContext = createContext<ErrorState | null>(null);

const createServerErrorFactory = (setError: ErrorState['setError']) => () => {
  const serverError = new Error(BookingError.INTERNAL_SERVER_ERROR);

  setError({
    error: serverError,
    isError: true,
  });

  logDataError(BookingError.INTERNAL_SERVER_ERROR);
};

export const useError = (): useErrorReturnType => {
  const context = useContext(ErrorContext);

  if (!context) {
    throw new Error('Please use ErrorProvider in parent component');
  }

  const setServerError = createServerErrorFactory(context.setError);

  return {
    ...context,
    setServerError,
  };
};

export const ErrorHook = ({
  apolloErrorObservable,
  children,
}: ErrorHookProps) => {
  const [error, setError] = useState<ErrorInfo | undefined>();
  const { None } = Client;
  const translation = useLocale();
  const ErrorAppMsgApp = translation.generic.error.latestAppVersion;
  const ErrorWebTitle = translation.generic.error.webTitle;
  const { payload, payloadError, payloadErrorTitle, platform, tokenError } =
    usePayload();

  const layoutErrorTheme = {
    accountProvider: payload?.accountProvider ?? AccountProvider.PP,
    membershipType: payload?.membershipType ?? None,
  };

  const tokenMessage =
    platform === 'android' || platform === 'ios'
      ? ErrorAppMsgApp
      : ErrorWebTitle;

  const layoutErrorProps = tokenError
    ? {
        payloadErrorMessage: tokenMessage,
        payloadErrorTitle,
      }
    : {
        payloadErrorButton: error?.button,
        payloadErrorMessage: error?.message,
        payloadErrorMobileMessage: error?.mobileMessage,
        payloadErrorTitle: error?.title,
      };

  const setServerError = createServerErrorFactory(setError);

  const err: ErrorHandler = ({ networkError }) => {
    if (networkError) setServerError();
  };

  useEffect(() => {
    let unsubscribe: () => void;

    if (apolloErrorObservable)
      unsubscribe = apolloErrorObservable.subscribe(err);

    return () => unsubscribe?.();
  }, [apolloErrorObservable]);

  useEffect(() => {
    if (payloadError) {
      setError({
        error: null,
        isError: payloadError,
        message: payloadErrorTitle,
      });
    } else if (tokenError) {
      setError({ error: null, isError: true, message: tokenError });
    }
  }, [tokenError, payloadError, payloadErrorTitle]);

  return (
    <>
      <ErrorContext.Provider
        value={{
          error,
          setError,
        }}
      >
        <div>
          {error ? (
            <LayoutError
              {...layoutErrorProps}
              payloadPlatform={platform ?? ''}
              payloadTheme={layoutErrorTheme}
            />
          ) : (
            <>{children}</>
          )}
        </div>
      </ErrorContext.Provider>
    </>
  );
};
