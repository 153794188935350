import { AccountProvider, Client } from '@collinsonx/constants/enums';
import {
  LogoHSBC,
  LogoLK,
  LogoMastercard,
  LogoPP,
} from '@collinsonx/design-system/assets/logo';
import { Box } from '@collinsonx/design-system/core';
import { ReactNode } from 'react';

import classes from './AppLogo.module.css';

const { LK, PP } = AccountProvider;
const { Mastercard_HSBC } = Client;
const { Mastercard_MCAE } = Client;

const logos: Record<AccountProvider | string, ReactNode> = {
  [LK]: (
    <Box className={classes.lk} data-testid="appLogo">
      <LogoLK />
    </Box>
  ),

  [Mastercard_HSBC]: (
    <Box className={classes.mastercardHsbc} data-testid="appLogo">
      <LogoHSBC />
    </Box>
  ),
  [Mastercard_MCAE]: (
    <Box className={classes.mastercard} data-testid="appLogo">
      <LogoMastercard />
    </Box>
  ),
  [PP]: (
    <Box className={classes.pp} data-testid="appLogo">
      <LogoPP />
    </Box>
  ),
};

interface AppLogoProps {
  accountProvider: AccountProvider;
  membershipType?: Client;
}

const AppLogo = ({ accountProvider, membershipType }: AppLogoProps) => {
  if (membershipType && logos[membershipType]) {
    return logos[membershipType];
  }
  if (accountProvider) {
    return logos[accountProvider];
  }
  return logos[PP];
};

export default AppLogo;
