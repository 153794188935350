import { AccountProvider } from '@collinsonx/constants/enums';
import { MantineThemeOverride } from '@collinsonx/design-system/core';
import { experienceX } from '@collinsonx/design-system/themes';
import {
  Consumer,
  LinkedAccount,
} from '@collinsonx/utils/generatedTypes/graphql';
import dayjsTz from '@collinsonx/utils/lib/dayjsTz';
import { PRODUCTION_DOMAIN } from 'config/constants';
import { BookingError } from 'config/datadog';
import { STORAGE_NAMESPACE } from 'config/storage';
import dayjs from 'dayjs';
import { Be_Vietnam_Pro } from 'next/font/google';
import router from 'next/router';
import { BridgePayload } from 'types/booking';

import { logDataError } from './dataLogs';

const LOUNGE_HOURS_OFFSET = 3;

export const getLoungeArrivalTime = (date: Date): string =>
  dayjsTz(date).subtract(LOUNGE_HOURS_OFFSET, 'hours').format('HH:mm');

const beVietnamPro = Be_Vietnam_Pro({
  style: ['normal'],
  subsets: ['latin'],
  weight: ['400', '600', '700'],
});

const themes = {
  experienceX,
};

const themeKey = process.env.NEXT_PUBLIC_SESSION_THEME;
let theme: MantineThemeOverride;

export const getThemeKey = () => themeKey || 'experienceX';

export const getTheme = () => {
  if (theme) {
    return theme;
  }
  try {
    theme = themes[themeKey as keyof typeof themes]({
      fontFamily: beVietnamPro.style.fontFamily,
    });
  } catch (e) {
    log(`Theme '${themeKey}' not found`);
    theme = experienceX({
      fontFamily: beVietnamPro.style.fontFamily,
    });
  }
  return theme;
};

const { LK, PP } = AccountProvider;

/**
 * Basic field validations for object
 * @param object
 * @param requiredKeys
 * @returns
 */
export const hasRequired = (object: any, requiredKeys: string[]) =>
  Object.keys(object).filter((key) => requiredKeys.includes(key)).length ===
    requiredKeys.length &&
  String(object.externalId).length >= 1 &&
  (object.accountProvider === PP || object.accountProvider === LK);

export const getItem = (key: string) => {
  try {
    return sessionStorage.getItem(`${STORAGE_NAMESPACE}_${key}`);
  } catch (e) {
    logDataError(BookingError.GET_SESSION_STORAGE, {
      customError: e as Error,
    });
  }
};

export const setItem = (key: string, value: string) => {
  try {
    return sessionStorage.setItem(`${STORAGE_NAMESPACE}_${key}`, value);
  } catch (e) {
    logDataError(BookingError.SET_SESSION_STORAGE, {
      customError: e as Error,
    });
  }
};

export const removeItem = (key: string) => {
  try {
    return sessionStorage.removeItem(`${STORAGE_NAMESPACE}_${key}`);
  } catch (e) {
    logDataError(BookingError.REMOVE_SESSION_STORAGE, {
      customError: e as Error,
    });
  }
};

export const log = (...args: any[]) => {
  if (window?.location.host !== PRODUCTION_DOMAIN) console.log(...args);
};

export const errorlog = (...args: any[]) => {
  if (window?.location.host !== PRODUCTION_DOMAIN) console.error(...args);
};

export const sendMobileEvent = (windowObj: any = {}, value: any) => {
  if (windowObj.Android && windowObj.Android.onWebViewEvent) {
    windowObj.Android.onWebViewEvent(value);
  } else if (
    windowObj.webkit &&
    windowObj.webkit.messageHandlers &&
    windowObj.webkit.messageHandlers.onWebViewEvent &&
    windowObj.webkit.messageHandlers.onWebViewEvent.postMessage
  ) {
    windowObj.webkit.messageHandlers.onWebViewEvent.postMessage(value);
  } else {
    log('ERROR: Unable to detect mobile environment');
  }
};

export const accountIsEqual =
  (payload: BridgePayload | undefined) => (item: LinkedAccount) =>
    String(item.externalID) === String(payload?.externalId) &&
    (item.provider as unknown as AccountProvider) === payload?.accountProvider;

export const consumerIsValid = (consumer: Consumer) => {
  const { dateOfBirth, firstName, lastName } = consumer || {};
  return consumer && firstName && lastName && dateOfBirth;
};

export const redirectTo = (path: string) => {
  router.push({
    pathname: path,
  });
};

export const checkHoursDiff = (startDate: string, endDate: string) => {
  const date = dayjs(startDate);
  return date.diff(endDate, 'hour', true);
};

export * from './dataLogs';
