export enum BookingError {
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  ERR_BOOKING_ALREADY_CANCELLED = 'ERR_BOOKING_ALREADY_CANCELLED',
  ERR_BOOKING_AUTHORIZATION = 'ERR_BOOKING_AUTHORIZATION',
  ERR_BOOKING_NOT_FOUND = 'ERR_BOOKING_NOT_FOUND',
  ERR_BOOKING_NOT_OWNED = 'ERR_BOOKING_NOT_OWNED',
  ERR_CANCELATION_NOT_ALLOWED = 'ERR_CANCELATION_NOT_ALLOWED',
  ERR_CANCELLATION_FAILED = 'ERR_CANCELLATION_FAILED',
  ERR_CANCELLATION_FAILED_WITH_SUCCESS = 'ERR_CANCELLATION_FAILED_WITH_SUCCESS',
  ERR_MEMBERSHIP_ALREADY_CONNECTED = 'ERR_MEMBERSHIP_ALREADY_CONNECTED',
  ERR_SOMETHING_WENT_WRONG = 'ERR_SOMETHING_WENT_WRONG',
  ERR_TOKEN_INVALID_OR_EXPIRED = 'ERR_TOKEN_INVALID_OR_EXPIRED',
  GENERIC_ERROR = 'GENERIC_ERROR',
  GET_SESSION_STORAGE = 'GET_SESSION_STORAGE',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  PAYLOAD_VALIDATION = 'PAYLOAD_VALIDATION',
  REMOVE_SESSION_STORAGE = 'REMOVE_SESSION_STORAGE',
  SET_SESSION_STORAGE = 'SET_SESSION_STORAGE',
  UNABLE_TO_RETRIEVE_JWT_OR_LOUNGE = 'UNABLE_TO_RETRIEVE_JWT_OR_LOUNGE',
}

export enum BookingInfo {
  CREATE_PDF_FAILED = 'CREATE_PDF_FAILED',
  URL_CHANGE = 'URL_CHANGE',
}

export enum ANALYTICS_TAGS {
  ON_CHANGE_DATE = 'CG_Flight_Date_Chk_Avl',
  ON_CHANGE_DATE_EDIT = 'CG_Flight_Date_Chk_Avl_Amnd',
  ON_CHANGE_DATE_ERROR = 'ERR_Flight_Date_Chk_Avl',
  ON_CHANGE_DATE_ERROR_EDIT = 'ERR_Flight_Date_Chk_Avl_Amnd',
  ON_CHANGE_EMAIL_ADDRESS = 'CHG_Email_Address',
  ON_CHANGE_ERROR_ATTENDEES_AVL = 'ERR_Number_Chk_Avl',
  ON_CHANGE_ERROR_ATTENDEES_AVL_EDIT = 'ERR_Number_Chk_Avl_Amnd',
  ON_CHANGE_FLIGHT_NUMBER = 'CG_Flight_Number_Chk_Avl',
  ON_CHANGE_FLIGHT_NUMBER_EDIT = 'CG_Flight_Number_Chk_Avl_Amnd',
  ON_CHANGE_FLIGHT_NUMBER_ERROR = 'ERR_Flight_Number_Chk_Avl',
  ON_CHANGE_FLIGHT_NUMBER_ERROR_EDIT = 'ERR_Flight_Number_Chk_Avl_Amnd',
  ON_CHECK_CODE_CHANGE = 'CG_Email_Code',
  ON_CHECK_CODE_ENTER = 'On_PG_Email_Code',
  ON_CHECK_CODE_VERIFY = 'Ck_BTN_Verify_Email_Code',
  ON_CONFIRM_AMEND_PG = 'On_PG_Sv_Chng_Amnd',
  ON_CONFIRM_AMEND_SAVE = 'CK_BTN_Sv_Chng_Amnd',
  ON_CONFIRMED_BTN_DECLINE = 'On_PG_Amnd_Dclnd',
  ON_CONFIRMED_BTN_DOWNLOAD_AMEND = 'CK_BTN__Amnd_Cfnd',
  ON_CONTINUE_BUTTON_AVI = 'CK_BTN_Chk_Avl',
  ON_CONTINUE_BUTTON_AVI_EDIT = 'CK_BTN_Chk_Avl_Amnd',
  ON_CONTINUE_CLICK = 'CK_BTN_Continue',
  ON_HIT_BACK_BUTTON = 'CK_BTN_Back_',
  ON_INDICATOR_LOADED_OFF = 'On_Bsns_Indicator_OFF',
  ON_INDICATOR_LOADED_ON = 'On_Bsns_Indicator_ON',
  ON_INDICATOR_REACHED_WITH_SCROLL = 'On_Bsns_Indicator_Scrolled',
  ON_INDICATOR_SELECTED_LEVEL = 'CK_Pick_Slot_Bsns_Indicator',
  ON_PAGE_CONFIRMED_BACK_BTN = 'Ck_BTN_Back_Bk_Cfnd',
  ON_PAGE_CONFIRMED_BTN_DOWNLOAD = 'CK_BTN__Bk_Cfnd',
  ON_PAGE_CONFIRMED_BTN_RFUND_DOWNLOAD = 'CK_BTN__Amnd_Cfnd_Rfnd',
  ON_PAGE_ENTER_CHECKAVAILABILITY = 'On_PG_Chk_Avl',
  ON_PAGE_ENTER_CHECKAVAILABILITY_EDIT = 'On_PG_Chk_Avl_Amnd',
  ON_PAGE_ENTER_CONFIRMED = 'On_PG_Bk_Cfnd',
  ON_PAGE_ENTER_CONFIRMED_AMEND = 'On_PG_Amnd_Cfnd',
  ON_PAGE_ENTER_EMAIL = 'On_PG_Enter_Email',
  ON_PAYMENT_CONTINUE = 'CK_BTN_Confirm_G_T_Pmt',
  ON_PAYMENT_ENTER = 'On_PG_G_T_Pmt',
  ON_PAYMENT_PROCESSED = 'On_PG_Processing_Pmt',
  ON_REFUND_AMENDMENT_ENTER = 'On_PG_Amnd_Cfnd_Rfnd',
  ON_REFUND_FAILED_AMEND = 'CK_BTN__Amnd_Cfnd_Rfnd_Err',
  ON_SIGNUP_PAGE_CONCENT = 'Tick_Consent_Upd_Dtl',
  ON_SIGNUP_PAGE_CONFIRM = 'CK_BTN_Confirm_Upd_Dtl',
  ON_SIGNUP_PAGE_ENTER = 'On_PG_Upd_Dtl',
  ON_SIGNUP_PAGE_FIRSTNAME_UPDATE = 'CG_First_Name_Upd_Dtl',
  ON_SIGNUP_PAGE_LASTNAME_UPDATE = 'CG_Last_Name_Upd_Dtl',
  ON_SLOT_AMEND_CONFIRMED = 'CK_BTN_Confirm_Pick_Slot_Amnd',
  ON_SLOT_AMEND_ENTER = 'On_PG_Pick_Slot_Amnd',
  ON_SLOT_CHANGE = 'CK_Time_Slot_Pick_Slot ',
  ON_SLOT_CHANGED_AMEND = 'CK_Time_Slot_Pick_Slot_Amnd',
  ON_SLOT_CONTINUE = 'CK_BTN_Confirm_Pick_Slot',
  ON_SLOT_MISSED = 'On_PG_Slot_Mis',
  ON_SLOT_PG_ENTER = 'On_PG_Pick_Slot',
  ON_SLOT_SELECT_ANOTHER = 'CK_BTN_Sel_Slot_Mis',
  ON_SLOT_SELECT_GO_TO_LOUNGE = 'CK_BTN_GO_Slot_Mis',
  ON_TOOLTIP_SELECTED = 'CK_Question_Mark_Bsns_In',
}

export const DATADOG_ERROR_LOGS = {
  [BookingError.GENERIC_ERROR]: {
    action: 'safe function call',
    title: 'generic error',
  },
  [BookingError.GET_SESSION_STORAGE]: {
    action: 'get session storage',
    title: 'get session storage',
  },
  [BookingError.INTERNAL_SERVER_ERROR]: {
    action: 'internal server error',
    title: BookingError.INTERNAL_SERVER_ERROR,
  },
  [BookingError.PAYLOAD_VALIDATION]: {
    action: 'payload validation',
    title: 'Payload validation failed',
  },
  [BookingError.REMOVE_SESSION_STORAGE]: {
    action: 'remove session storage',
    title: 'remove session storage',
  },
  [BookingError.SET_SESSION_STORAGE]: {
    action: 'set session storage',
    title: 'set session storage',
  },
  [BookingError.UNABLE_TO_RETRIEVE_JWT_OR_LOUNGE]: {
    action: 'fetch url params',
    title:
      'Unable to retrieve jwt token or lounge code from both query and storage',
  },
};

export const DATADOG_INFO_LOGS = {
  [BookingInfo.CREATE_PDF_FAILED]: 'Create Webview PDF Data URI Failed',
  [BookingInfo.URL_CHANGE]: 'url change',
};

export const PAGENAMES = {
  BOOKING_AMEND: 'Chk_Avl_Amnd',
  BOOKING_CONFIRMED: 'BookingConfirmed',
  CONFIRM_AMEND: 'Sv_Chng_Amnd',
  CONFIRM_CREATE: 'G_T_Pmt',
  FAILURE_AMEND_REFUND: 'Refund_fail',
  FAULURE_BOOKING: 'Slot_Mis',
  INDEX: 'Chk_Avl',
  PICK_SLOT: 'Pick_Slot',
  SLOTAMEND: 'Slot_Amnd',
};
