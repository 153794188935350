import { MantineProvider } from '@collinsonx/design-system/core';
import { resolver } from '@collinsonx/design-system/themes/baseTheme';
import { mantineTheme } from '@lib/theme';
import usePayload from 'hooks/payload';
import { PropsWithChildren } from 'react';

export default function MantineWrapper({
  children,
}: Readonly<PropsWithChildren>) {
  const { payload } = usePayload();

  return (
    <MantineProvider
      cssVariablesResolver={resolver}
      theme={mantineTheme(payload)}
    >
      {children}
    </MantineProvider>
  );
}
