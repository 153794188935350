import { useDecision } from '@optimizely/react-sdk';
import { FEATURE_FLAGS } from 'config/optimizely';

type UseFeatureFlagOptions = {
  enabledByDefault: boolean;
};

const useFeatureFlag = (
  featureFlag: FEATURE_FLAGS,
  options?: UseFeatureFlagOptions
): boolean => {
  const [decision] = useDecision(featureFlag);

  const { enabledByDefault } = options ?? {};

  let useFeature = enabledByDefault || false;

  if (decision?.enabled) useFeature = decision.variationKey === 'on';

  return useFeature;
};

export default useFeatureFlag;
