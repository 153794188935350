export const cookiesNames = {
  consumerid: 'EXPERIENCE_X_CONSUMER_ID',
};

export const STORAGE_NAMESPACE = 'PREBOOKING';

export const LOUNGE_CODE = 'LOUNGE_CODE';
export const LANGUAGE = 'LANGUAGE';
export const JWT = 'JWT';
export const REFERRER = 'REFERRER';
export const PLATFORM = 'PLATFORM';
export const VERSION = 'VERSION';
export const ALLOW_LOCAL = 'ALLOW_LOCAL';
export const PATH_NAME = 'PATH_NAME';
export const BOKING_MODE_STATE = 'MODE';
export const ORIGINAL_BOOKING_DETAILS = 'ORIGINAL_BOOKING_DETAILS';
export const USER_ID = 'xx';
export const USER_META = 'USER_META';
