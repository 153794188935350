import getCookieHandler from '@collinsonx/utils/lib/cookieHandler';
import { ThirdPartyPasswordless } from '@collinsonx/utils/supertokens';
import Session, { InputType } from 'supertokens-auth-react/recipe/session';

import appInfo from './appInfo';
import getWindowHandler from './windowHandler';

async function userContextHandler(context: any) {
  const { requestInit, userContext = {} } = context;
  const body = requestInit.body ? JSON.parse(requestInit.body) : {};

  requestInit.body = JSON.stringify({ ...body, userContext });

  return context;
}

interface FrontendConfigOptions {
  isInIframe: boolean;
}

export const superTokensConfig = ({ isInIframe }: FrontendConfigOptions) => ({
  appInfo,
  cookieHandler: getCookieHandler,
  isInIframe,
  recipeList: [
    Session.init({
      isInIframe,
      preAPIHook: userContextHandler,
      tokenTransferMethod: 'header',
    } as InputType),
    ThirdPartyPasswordless.init({
      contactMethod: 'EMAIL',
      preAPIHook: userContextHandler,
    }),
  ],
  windowHandler: getWindowHandler,
});
