import { BookingQueryParams } from '@collinsonx/constants/enums';
import { removeItem, setItem } from '@lib';
import {
  JWT,
  LANGUAGE,
  LOUNGE_CODE,
  PATH_NAME,
  PLATFORM,
  REFERRER,
  USER_ID,
  USER_META,
  VERSION,
} from 'config/storage';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

const {
  jwt: jwtParam,
  ln,
  loungeCode: lcParam,
  platform: platParam,
  referrer: referParam,
  version,
} = BookingQueryParams;

interface AuthWrapperProps {
  children: React.ReactNode;
}
const checkIsAllowed = (pathname: string) =>
  pathname.startsWith('/auth') || pathname.startsWith('/signup');

const SysAuth = ({ children }: AuthWrapperProps) => {
  const [show, setShow] = useState(false);
  const router = useRouter();

  const session: any = useSessionContext();
  useEffect(() => {
    const { accessTokenPayload = {} } = session as any;
    if (accessTokenPayload.experiences) {
      setItem(
        USER_META,
        JSON.stringify({ experiences: accessTokenPayload.experiences })
      );
    }
  }, [session]);

  useEffect(() => {
    if (
      session.loading === false &&
      session.doesSessionExist === false &&
      typeof window !== undefined
    ) {
      removeItem(USER_META);

      const urlParams = new URLSearchParams(window.location.search);
      const tokenParam = urlParams.get(jwtParam);
      const loungeParam = urlParams.get(lcParam);
      const referrerParam = urlParams.get(referParam);
      const platformParam = urlParams.get(platParam);
      const platln = urlParams.get(ln);
      const platVersion = urlParams.get(version);

      if (tokenParam && loungeParam) {
        setItem(LOUNGE_CODE, loungeParam);
        setItem(JWT, tokenParam);
        setItem(PLATFORM, platformParam ?? '');
        setItem(LANGUAGE, platln ?? 'en');
        setItem(VERSION, platVersion ?? process.env.NEXT_PUBLIC_VERSION ?? '');
        setItem(PATH_NAME, JSON.stringify(router));
      }

      if (referrerParam) {
        setItem(REFERRER, referrerParam ?? '');
      }

      if (!checkIsAllowed(window.location.pathname)) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const idParam = urlSearchParams.get('bookingId');
        if (idParam) {
          window.location.href = `/auth/login?bookingId=${idParam}`;
        } else {
          window.location.href = '/auth/login';
        }
      }
    }

    const isLoggedIn =
      session.loading === false && session.doesSessionExist === true;

    if (isLoggedIn || checkIsAllowed(window.location.pathname)) {
      if (session.userId && typeof session.userId === 'string') {
        setItem(USER_ID, session.userId);
      }
      setShow(true);
    } else {
      setShow(false);
    }
  }, [session]);

  return show ? (
    <>{children}</>
  ) : (
    <div
      style={{
        backgroundColor: '#ffffff',
        height: '100vh',
        left: 0,
        padding: 0,
        position: 'fixed',
        top: 0,
        width: '100vw',
      }}
    ></div>
  );
};

export default SysAuth;
